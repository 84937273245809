import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "../styles/ClipPathCSS.module.scss";

ClipPathCSS.propTypes = {
  desktopPath: PropTypes.string,
  desktopBackgroundColor: PropTypes.string,
  mobilePath: PropTypes.string.isRequired,
  uniqueClass: PropTypes.string.isRequired,
};

function ClipPathCSS({
  mobilePath,
  desktopPath,
  uniqueClass,
  desktopBackgroundColor,
}) {
  const pathRef = useRef();

  useEffect(() => {
    const targetElement = pathRef.current;
    let style;
    let desktop = false;
    if (window.innerWidth >= 992 && desktopPath) {
      style = desktopPath;
      desktop = true;
    } else {
      style = mobilePath;
    }
    style = style.split(" ");
    for (let i = 0; i < style.length; i++) {
      if (style[i].includes("%")) {
        style[i] = style[i].replace("%", "");
        let arr = style[i].split(",");
        let x = parseFloat(arr[0]);
        let y = parseFloat(arr[1]);
        x = x * targetElement.offsetWidth;
        y = y * targetElement.offsetHeight;
        style[i] = x + "," + y;
      }
    }
    style = style.join(" ");
    if (desktop && desktopBackgroundColor) {
      targetElement.style.clipPath = `path('${style}')`;
      targetElement.style.backgroundColor = desktopBackgroundColor;
    } else {
      targetElement.setAttribute("style", `clip-path: path('${style}')`);
    }
  }, [desktopPath, mobilePath, desktopBackgroundColor]);

  return <div className={styles[uniqueClass]} ref={pathRef}></div>;
}

export default ClipPathCSS;
