import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/ButtonBack.module.scss";
const classNames = require("classnames/bind");

ButtonBack.propTypes = {
	modalContent: PropTypes.string.isRequired,
	setModalContent: PropTypes.func.isRequired,
	setUploadedImage: PropTypes.func.isRequired,
};

function ButtonBack({ modalContent, setModalContent, setUploadedImage }) {
	const handleClick = () => {
		setModalContent("LandingContent");
		setUploadedImage("empty");
	};

	const themeClass =
		modalContent === "poisonScanner"
			? styles.orange
			: modalContent === "ChatBot"
			? styles.white
			: styles.hidden;

	const classStyles = {
		backButton: styles.backButton,
		themeColors: themeClass,
	};
	const cx = classNames.bind(classStyles);

	const buttonClass = cx({
		backButton: true,
		themeColors: true,
	});

	return (
		<button className={buttonClass} onClick={handleClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="37"
				height="25"
				viewBox="0 0 37.463 24.986"
			>
				<path
					d="M21.454,11.73a1.7,1.7,0,0,1,.013,2.394l-7.9,7.925h30.1a1.692,1.692,0,0,1,0,3.383h-30.1l7.912,7.925a1.713,1.713,0,0,1-.013,2.394,1.685,1.685,0,0,1-2.381-.013L8.363,24.938h0a1.9,1.9,0,0,1-.351-.534,1.615,1.615,0,0,1-.13-.651,1.7,1.7,0,0,1,.481-1.184l10.722-10.8A1.658,1.658,0,0,1,21.454,11.73Z"
					transform="translate(-7.882 -11.252)"
					fill="currentColor"
				/>
			</svg>
		</button>
	);
}

export default ButtonBack;
