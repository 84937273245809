import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

Animation.propTypes = {
	classProp: PropTypes.string.isRequired,
	gifSrc: PropTypes.string.isRequired,
	altText: PropTypes.string.isRequired,
};

function Animation({ classProp, gifSrc, altText }) {
	const targetRef = useRef();
	const animationRef = useRef();
  
	const [animationStarted, setAnimationStarted] = useState(false);

	useEffect(() => {
		// if (!animationRef.current.complete) {
		// 	console.log("animation not loading");
		// }
		function handleScroll() {
			const targetTop = animationRef.current.getBoundingClientRect().top;
			const viewPortHeight = window.innerHeight;
			const targetPosition = targetTop - viewPortHeight / 2;
			if (targetPosition <= 0 && animationStarted !== true) {
				const source = animationRef.current.src;
				animationRef.current.src = source;
				setAnimationStarted(true);
			}
		}
		document.addEventListener("scroll", handleScroll);

		return () => document.removeEventListener("scroll", handleScroll);
	});

	return (
		<div className={classProp}>
			<img ref={animationRef} src={gifSrc} alt={altText} className="animationGif"/>
			<div ref={targetRef}></div>
		</div>
	);
}

export default Animation;
