import React from "react";
import SideBySide from "./SideBySide";
import styles from "../styles/social-callouts.module.scss";
import logoInstagram from "../media/images/social/instagram-icon.svg";
import logoTikTok from "../media/images/social/tiktok-icon.svg";

function SocialCallouts() {
	return (
		<>
			<div className={styles.section}>
				<p className={styles.text}>Follow:</p>
				<div className={styles.wrapper}>
					<SideBySide
						source={logoInstagram}
						imgAlt={"Instagram icon"}
						hrefContent={"Instagram"}
						destination={"https://www.instagram.com/dogseateverything/"}
						classModifier={"social"}
						blank={true}
						ariaLabel={"View the Dogs Eat Everything Instagram page."}
					/>

					<SideBySide
						source={logoTikTok}
						imgAlt={"TikTok Icon"}
						hrefContent={"TikTok"}
						destination={"https://www.tiktok.com/@dogseateverything"}
						classModifier={"social"}
						blank={true}
						ariaLabel={"View the Dogs Eat Everything TikTok page."}
					/>
				</div>
			</div>
		</>
	);
}

export default SocialCallouts;
