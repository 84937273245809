import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../styles/CookieModal.module.scss";
const classNames = require("classnames/bind");

CookieModal.propTypes = {
  cookieModalActive: PropTypes.bool.isRequired,
  setCookieModalActive: PropTypes.func.isRequired,
  resetDefaultScrolling: PropTypes.func.isRequired,
};

function CookieModal({
  cookieModalActive,
  setCookieModalActive,
  resetDefaultScrolling,
}) {
  useEffect(() => {
    function checkCookie() {
      const visited = getCookie("visited");
      if (visited === "true") {
        return;
      } else {
        setCookieModalActive(true);
        stopDefaultScrolling();
      }
    }
    checkCookie();
  }, [setCookieModalActive]);

  function setCookie(cookieName, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=true; " + expires;
  }

  function getCookie(cookie) {
    const cookieName = cookie + "=";
    const cookieArray = document.cookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let currentCookie = cookieArray[i];
      while (currentCookie.charAt(0) === " ") {
        currentCookie = currentCookie.substring(1);
      }
      if (currentCookie.indexOf(cookieName) === 0) {
        return currentCookie.substring(cookieName.length, currentCookie.length);
      }
    }
    return;
  }

  const classStyles = {
    cookieModal: styles.cookieModal,
    active: styles.active,
    underlay: styles.underlay,
    modal: "modal",
    open: "open",
  };

  const cx = classNames.bind(classStyles);

  const cookieClass = cx({
    cookieModal: true,
    active: cookieModalActive,
    modal: true,
    open: cookieModalActive,
  });

  const underlayClass = cx({
    underlay: cookieModalActive,
  });

  const stopDefaultScrolling = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
  };

  function closeModal() {
    setCookieModalActive(false);
    setCookie("visited", 15);
    resetDefaultScrolling();
  }

  return (
    <>
      <div className={underlayClass}></div>
      <div className={cookieClass}>
        <button className={styles.buttonCloseX} onClick={closeModal}></button>
        <p className={styles.header}>Cookie Policy</p>
        <p className={styles.text}>
          This website uses cookies, including third-party cookies, which allows
          us to obtain information about your visit to our website. By
          continuing to use this website you consent to the use of cookies.
        </p>
        <button className={styles.buttonClose} onClick={closeModal}>
          Close
        </button>
      </div>
    </>
  );
}

export default CookieModal;
