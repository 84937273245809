import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Nav.module.scss";
import navHome from "../media/images/nav/icon-home.svg";
import navDoNotEat from "../media/images/nav/icon-do-not-eat.svg";
import navPoisonSigns from "../media/images/nav/icon-poison-signs.svg";

const classNames = require("classnames/bind");

Nav.propTypes = {
	sectionRefs: PropTypes.object.isRequired,
	scannerActive: PropTypes.bool.isRequired,
	setDisclaimerActive: PropTypes.func.isRequired,
	setOfflineActive: PropTypes.func.isRequired,
	setTargetModal: PropTypes.func.isRequired,
};

function Nav({
	sectionRefs,
	scannerActive,
	setDisclaimerActive,
	setOfflineActive,
	setTargetModal,
}) {
	const [isActive, setActive] = useState("poison-control");

	const classStyles = {
		scannerButton: styles.scannerButton,
		active: styles.active,
	};

	const cx = classNames.bind(classStyles);

	const scannerClass = cx({
		scannerButton: true,
		active: scannerActive,
	});

	//used to loop in the handleScroll function, references DOM elements
	const navRefs = {
		homeNavRef: useRef(),
		eatNavRef: useRef(),
		poisonNavRef: useRef(),
	};

	const navJson = [
		{
			name: "Home",
			source: navHome,
			destination: "poison-control",
			navRef: navRefs.homeNavRef,
			activeClassValue: "active",
			ariaLabel: "Scroll to the top of the page.",
		},
		{
			name: "Do Not Eat",
			source: navDoNotEat,
			destination: "human-food",
			navRef: navRefs.eatNavRef,
			activeClassValue: "",
			ariaLabel: "View a list of human foods that dogs should not eat.",
		},
		{
			name: "Poison Signs",
			source: navPoisonSigns,
			destination: "poison-signs",
			navRef: navRefs.poisonNavRef,
			activeClassValue: "",
			ariaLabel: "View the signs of possible poisoning.",
		},
	];

	const navLinkList = navJson.map((data) => {
		const classList = `${data.destination} ${
			isActive === data.destination ? styles.active : ""
		} gtm-top-nav`;
		const linkHref = `#${data.destination}`;

		return (
			<a
				className={`${styles.navlink} ${classList}`}
				href={linkHref}
				ref={data.navRef}
				key={data.name}
				aria-label={data.ariaLabel}
				data-gtm-event-label={data.name}
			>
				<img src={data.source} alt={"null"} />
				{data.name}
			</a>
		);
	});

	useEffect(() => {
		function handleScroll() {
			for (const section in sectionRefs) {
				const targetTop =
					sectionRefs[section].current.getBoundingClientRect().top;
				const viewPortHeight = window.innerHeight;
				const targetPosition = targetTop - viewPortHeight / 2;
				if (targetPosition <= 0) {
					let id = sectionRefs[section].current.id;
					setActive(id);
				}
			}
		}
		document.addEventListener("scroll", handleScroll);

		return () => document.removeEventListener("scroll", handleScroll);
	});

	function handleClick() {
		if (!navigator.onLine) {
			setOfflineActive(true);
			stopDefaultScrolling();
			return;
		}
		setDisclaimerActive(true);
		setTargetModal("scanner");
		stopDefaultScrolling();
	}

	const stopDefaultScrolling = () => {
		document.body.style.overflow = "hidden";
		document.documentElement.style.overflow = "hidden";
	};
	// return for Navbar
	return (
		<nav className={styles.navbar}>
			<div className={styles.navList}>
				<button
					className={scannerClass + " gtm-link-internal"}
					onClick={handleClick}
					aria-label={"Open the poison food scanner."}
					data-gtm-event-label="Scan it"
				>
					<span>Scan it</span>
				</button>
				<div className={styles.navPlaceholder}></div>
				{navLinkList}
				<div className={styles.navPlaceholder}></div>
			</div>
		</nav>
	);
}

export default Nav;
