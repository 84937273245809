import React from "react";
import PoisonSign from "./PoisonSign";
import styles from "../styles/PoisonSignList.module.scss";
import { poisonSignsJSON } from "../data";
import SideBySide from "./SideBySide";
import warningIcon from "../media/images/global/icon-warning.svg";

function PoisonSignList() {
	return (
		<div className={styles.wrapper}>
			<SideBySide
				content={"Call your vet or the ASPCA Animal Poison Control Center at "}
				source={warningIcon}
				imgAlt={"Warning symbol"}
				destination={"tel:888-426-4435"}
				hrefContent={"888-426-4435."}
				classModifier={"warning"}
				ariaLabel={"Call ASPCA Animal Poison Control at 888-426-4435."}
			/>

			<div className={styles.list}>
				{poisonSignsJSON.map((sign) => {
					return <PoisonSign key={sign.id} data={sign} />;
				})}
			</div>
		</div>
	);
}

export default PoisonSignList;
