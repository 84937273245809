const ScannerDictionary = new Map([
	["Ace inhibitor", 3],
	["Acetaminophen", 3],
	["Acid", 3],
	["Adderall", 3],
	["Adhesive", 3],
	["Alcohol", 3],
	["Aleve", 3],
	["Algae", 3],
	["Aloe", 3],
	["Aloe vera", 3],
	["Almond", 3],
	["Amaryllis", 3],
	["Ambien", 3],
	["Amphetamine", 3],
	["Antibiotics", 3],
	["Antidepressant", 3],
	["Antifreeze", 3],
	["Antihistamine", 3],
	["Apricot", 3],
	["Aspirin", 3],
	["Automotive", 3],
	["Avocado", 3],
	["Azalea", 3],
	["Baking soda", 3],
	["Batteries", 3],
	["Battery", 3],
	["Benzo", 3],
	["Beta blocker", 3],
	["Bleach", 3],
	["Bread dough", 3],
	["Bug spray", 3],
	["Candy", 3],
	["Cannabis", 3],
	["Cbd", 3],
	["Chemical", 3],
	["Cherries", 3],
	["Cherry", 3],
	["Chives", 3],
	["Chocolate", 3],
	["Cigarette", 3],
	["Cleaner", 3],
	["Cocaine", 3],
	["Coffee", 3],
	["Cough medicine", 3],
	["Currants", 3],
	["Daffodil", 3],
	["Decongestant", 3],
	["Deet", 3],
	["Detergent", 3],
	["Diuretic", 3],
	["Dough", 3],
	["Drug", 3],
	["Easter lily", 3],
	["Edibles", 3],
	["English ivy", 3],
	["Estrogen", 3],
	["Ethanol", 3],
	["Eucalyptus", 3],
	["Fabric softener", 3],
	["Febreze", 3],
	["Fertilizer", 3],
	["Firecracker", 3],
	["Firework", 3],
	["Flea collar", 3],
	["Flea medication", 3],
	["Flower", 3],
	["Foxglove", 3],
	["Fungicide", 3],
	["Garlic", 3],
	["Gas", 3],
	["Geranium", 3],
	["Glow light", 3],
	["Glow stick", 3],
	["Glue", 3],
	["Gorilla glue", 3],
	["Grape", 3],
	["Grapes", 3],
	["Gum", 3],
	["Hand sanitizer", 3],
	["Hand warmer", 3],
	["Hemlock", 3],
	["Herbicide", 3],
	["Hops", 3],
	["Household", 3],
	["Ibuprofen", 3],
	["Insecticide", 3],
	["Iron", 3],
	["Jade", 3],
	["Kerosene", 3],
	["Lead", 3],
	["Leek", 3],
	["Lilies", 3],
	["Lily", 3],
	["Liquid", 3],
	["Lunesta", 3],
	["Macadamia", 3],
	["Marigold", 3],
	["Marijuana", 3],
	["Match", 3],
	["Matches", 3],
	["Medication", 3],
	["Medicine", 3],
	["Mold", 3],
	["Morphine", 3],
	["Mothballs", 3],
	["Mushroom", 3],
	["Naproxen", 3],
	["Narcan", 3],
	["Nicorette", 3],
	["Nicotine", 3],
	["Nightshade", 3],
	["Nsaid", 3],
	["Nuts", 3],
	["Ointment", 3],
	["Oleander", 3],
	["Onion", 3],
	["Opiate", 3],
	["Opioid", 3],
	["Paint", 3],
	["Peace lily", 3],
	["Peach", 3],
	["Pennies", 3],
	["Penny", 3],
	["Peony", 3],
	["Peppermint oil", 3],
	["Petroleum", 3],
	["Pesticide", 3],
	["Pill", 3],
	["Pit", 3],
	["Plant", 3],
	["Plastic", 3],
	["Playdoh", 3],
	["Playdough", 3],
	["Plum", 3],
	["Poinsettia", 3],
	["Poison", 3],
	["Pot", 3],
	["Pothos", 3],
	["Raisin", 3],
	["Rat poison", 3],
	["Raw meat", 3],
	["Rhododendron", 3],
	["Rhubarb", 3],
	["Rodenticide", 3],
	["Sago palm", 3],
	["Salt", 3],
	["Schefflera", 3],
	["Sleep aid", 3],
	["Sleeping pill", 3],
	["Soap", 3],
	["Solvent", 3],
	["Ssri", 3],
	["Sugarfree", 3],
	["Superglue", 3],
	["Tea", 3],
	["Tick collar", 3],
	["Tick medication", 3],
	["Tobacco", 3],
	["Tomato", 3],
	["Tomatoes", 3],
	["Toothpaste", 3],
	["Tulip", 3],
	["Tylenol", 3],
	["Uncooked dough", 3],
	["Uncooked meat", 3],
	["Vaseline", 3],
	["Viagra", 3],
	["Vitamin", 3],
	["Warfarin", 3],
	["Windshield fluid", 3],
	["Xylitol", 3],
	["Yew", 3],
	["Yucca", 3],
	["Zinc", 3],
	["Asparagus", 3],
	["Mushrooms", 3],
	["Peaches", 3],
	["Albuterol", 3],
	["5-hydroxytryptophan", 3],
	["Hydroxytryptophan", 3],
	["Alkali", 3],
	["AlphaLipoic acid", 3],
	["Amphetamines", 3],
	["Anticoagulant", 3],
	["Atenolol", 3],
	["Autumn crocus", 3],
	["Benazepril", 3],
	["Benzyl alcohol", 3],
	["Bismuth", 3],
	["Subsalicylate", 3],
	["Blue green algae", 3],
	["Carprofen", 3],
	["Carvedilol", 3],
	["Chrysanthemum", 3],
	["Cyclamen", 3],
	["Daffodils", 3],
	["De-icing salt", 3],
	["De-icing salts", 3],
	["Dogbane", 3],
	["Hemp", 3],
	["Enalapril", 3],
	["Etheylene glycol", 3],
	["Firocoxib", 3],
	["Slouxetine", 3],
	["Furosemide", 3],
	["Glow lights", 3],
	["Glow jewelry", 3],
	["Hydrochlorothiazide", 3],
	["Illicit drugs", 3],
	["Imidazoline", 3],
	["Decongestants", 3],
	["Ivermectin", 3],
	["Kalanchoe", 3],
	["Laundry detergent", 3],
	["Liquid fuels", 3],
	["Liquid portpourri", 3],
	["Lithium ion", 3],
	["Loperamide", 3],
	["Meloxicam", 3],
	["Metaldehyde", 3],
	["Methanol", 3],
	["Metronidazole", 3],
	["Midazolam", 3],
	["Hosta", 3],
	["Hostas", 3],
	["Olanzapine", 3],
	["Paroxetine", 3],
	["Permethrin", 3],
	["Phenazopyridine", 3],
	["Propylene glycol", 3],
	["Pseudoephedrine", 3],
	["Phenylephrine", 3],
	["Red maple leaves", 3],
	["Tea tree oil", 3],
	["Salami", 3],
	["Mustard", 3],
	["Soy sauce", 3],
	["Butter", 2],
	["Cheese", 2],
	["Ice cream", 2],
	["Qtip", 2],
	["Q-tip", 2],
	["Milk", 2],
	["Popcorn", 2],
	["Poison ivy", 2],
	["Prunes", 2],
	["Apple", 1],
	["Banana", 1],
	["Beans", 1],
	["Blueberries", 1],
	["Bread", 1],
	["Carrot", 1],
	["Carrots", 1],
	["Cat poop", 1],
	["Celery", 1],
	["Corn", 1],
	["Crap", 1],
	["Cucumber", 1],
	["Dirt", 1],
	["Feces", 1],
	["Noodles", 1],
	["Orange", 1],
	["Paper", 1],
	["Peanut butter", 1],
	["Poo", 1],
	["Poop", 1],
	["Potato", 1],
	["Rice", 1],
	["Turd", 1],
	["Oranges", 1],
	["Lettuce", 1],
	["Mango", 1],
	["Watermelon", 1],
	["Filet mignon", 1],
	["Ham", 1],
	["Kiwi", 1],
	["Broccoli", 1],
	["Cauliflower", 1],
	["Yam", 1],
	["Pineapple", 1],
	["Yogurt", 1],
	["Tuna", 1],
	["Shrimp", 1],
	["Ketchup", 1],
	["Sardines", 1],
	["Pasta", 1],
	["Strawberry", 1],
	["Strawberries", 1],
	["Ginger", 1],
	["Pumpkin", 1],
]);
// console.log(ScannerDictionary);

export default ScannerDictionary;
