import React from "react";
import styles from "../styles/PartnerLogoList.module.scss";
import { partnerLogoJson } from "../data.js";

function PartnerLogoList() {
  const logoList = partnerLogoJson.map((logo) => {
    return (
      <a
        href={logo.url}
        key={logo.name}
        target="_blank"
        rel="noreferrer"
        className="gtm-link-external"
        data-gtm-event-label={logo.name}
      >
        <img src={logo.src} alt={logo.name} className={styles.partnerLogo} />
      </a>
    );
  });

  return <div className={styles.wrapper}>{logoList}</div>;
}

export default PartnerLogoList;
