import React from "react";
import styles from "../styles/PoisonControl.module.scss";
const classNames = require("classnames/bind");

function PoisonControl() {
  const classStyles = {
    text: styles.text,
    disclaimer: styles.disclaimer,
  };

  const cx = classNames.bind(classStyles);

  const disclaimerClass = cx({
    text: true,
    disclaimer: true,
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.text}>ASPCA Animal Poison Control</p>
        <a
          className={styles.link + " gtm-top-nav"}
          href="tel:888-426-4435"
          aria-label={"Call ASPCA Animal Poison Control at 888-426-4435."}
          data-gtm-event-label="Call 888-426-4435"
        >
          Call 888-426-4435
        </a>
        <p className={disclaimerClass}>(consultation fee may apply)</p>
      </div>
    </div>
  );
}

export default PoisonControl;
