import React, { useState } from "react";
import styles from "../styles/Sources.module.scss";
const classNames = require("classnames/bind");

function Sources() {
  const [isActive, setIsActive] = useState(false);

  function handleToggle() {
    setIsActive(!isActive);
  }

  const classStyles = {
    active: styles.active,
    sources: styles.sources,
    sourcesButton: styles["sources-button"],
    container: styles.container,
  };

  const cx = classNames.bind(classStyles);

  const sourcesClass = cx({
    sources: true,
    active: isActive,
  });

  const sourcesButtonClass = cx({
    sourcesButton: true,
    active: isActive,
  });

  const containerClass = cx({
    container: true,
    active: isActive,
  });

  return (
    <div className={sourcesClass}>
      <button
        className={sourcesButtonClass + " gtm-link-internal"}
        onClick={handleToggle}
        data-gtm-event-label="View References"
      >
        View References
      </button>
      <div className={containerClass}>
        <h3 className={styles.header}>References</h3>
        <button
          className={styles["close-button"] + " gtm-link-internal"}
          onClick={handleToggle}
          aria-label={"Close References tab"}
          data-gtm-event-label="Close References"
        ></button>
        <p className={styles.text}>
          <strong>1.</strong> American Society for the Prevention of Cruelty to
          Animals (ASPCA). <em>The Top Ten Pet Toxins of 2021</em>.
          <strong> 2.</strong> ASPCA.
          <em>
            {
              " ASPCA Animal Poison Control Center Reaches 4 Million Cases of Pet Toxicity"
            }
          </em>
          <strong> 3.</strong> ASPCA.
          <em> People Foods to Avoid Feeding Your Pets</em>. 2022.
          <strong> 4.</strong>&nbsp;ASPCA. Animal Poison Control. 2022.
        </p>
      </div>
    </div>
  );
}

export default Sources;
