export default function getQueryString(string) {
	let params = new URLSearchParams(window.location.search);
	return params.get(string);
}

/* Implementation

  import getQueryString from "../functions/queryStringHandling";

  let queryStr = getQueryString("whoLetTheDogsOut");
		if (queryStr === "") {
			// do something here
		}
*/
