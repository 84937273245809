import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "../styles/ScannerButton.module.scss";
import ScannerDictionary from "../scannerDictionary.js";

ScannerButton.propTypes = {
	setUploadedImage: PropTypes.func.isRequired,
	setPoisonSeverity: PropTypes.func.isRequired,
};

function ScannerButton({ setUploadedImage, setPoisonSeverity }) {
	const inputRef = useRef();

	const handleNewImage = async () => {
		// get the image ref
		const input = inputRef.current;
		const imgFile = input.files[0];
		// convert the image to base64 format
		const uploadedImg = await getBase64(imgFile);
		// console.log(uploadedImg);
		// pass the uploadedImg file to the useState variable and update the Scanner UI
		setUploadedImage(uploadedImg);
		// isolate the base64 image string needed by the Cloud Vision API
		const base64Img = uploadedImg.substring(uploadedImg.indexOf(",") + 1);
		// construct the api POST request payload
		const reqPayload = constructPayload(base64Img);
		// send the POST request to Cloud Vision API and await the response
		const descriptions = await getImageDescriptions(base64Img, reqPayload);
		// compare the image descriptions from the API to our ScannerDescriptions Map
		const severity = determineImageContent(descriptions);
		// pass the severity value to the useState variable and update the Scanner UI
		if (severity > 0) {
			setPoisonSeverity(severity);
			return;
		}
		setPoisonSeverity(0);
	};

	const getBase64 = (file) => {
		const reader = new FileReader();
		return new Promise((resolve) => {
			// set up the eventListener for when the reader is finished
			reader.addEventListener("load", function (e) {
				// resolve the Promise and return the base64 image
				resolve(e.target.result);
			});
			// read the image file and convert it to base64
			reader.readAsDataURL(file);
		});
	};

	const constructPayload = (img) => {
		// create body object
		const visionReqObj = {
			requests: [
				{
					image: {
						content: img,
					},
					features: [
						{
							type: "LABEL_DETECTION",
							maxResults: 20,
						},
					],
				},
			],
		};
		// convert body object to JSON and return
		return JSON.stringify(visionReqObj);
	};

	const getImageDescriptions = async (img, payload) => {
		try {
			// creat variables for fetch()
			const visionApiUrl =
				"/api/scanner";
			const init = {
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
				body: payload,
			};
			// post to Cloud Vision API (aka Google Lens)
			const response = await fetch(visionApiUrl, init);
			// convert response to json
			const data = await response.json();
			// isolate the response array with objects that contain the image description
			const descriptionsArray = data.responses[0].labelAnnotations;
			// console.table(descriptionsArray);
			return descriptionsArray;
		} catch (err) {
			console.assert(err);
		}
	};

	function determineImageContent(descriptionsArray) {
		// iterate through the arr and look for specific foods
		for (let i = 0; i < descriptionsArray.length; i++) {
			let currentDesc = descriptionsArray[i].description;
			if (ScannerDictionary.has(currentDesc)) {
				// get severity value from dictionary and update poison scanner modal
				let severity = ScannerDictionary.get(currentDesc);
				// console.warn("Poison detected!!", currentDesc, severity);
				return severity;
			}
		}
		// if none of the image descritions are found in the ScannerDictionary
		// console.warn("No poison detected.");
		return;
	}

	return (
		<>
			<label htmlFor="cameraFileInput" className={styles.scannerButton}>
				<span>Use poison scanner</span>
				<input
					ref={inputRef}
					id="cameraFileInput"
					className={styles.scannerInput}
					type="file"
					accept="image/*"
					capture="environment"
					onChange={handleNewImage}
				/>
				<i className={styles.camera}></i>
			</label>
		</>
	);
}

export default ScannerButton;
