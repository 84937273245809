import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/PoisonControlCTA.module.scss";
const classNames = require("classnames/bind");

PosionControlCTA.propTypes = {
	poisonSeverity: PropTypes.number,
};

function PosionControlCTA({ poisonSeverity }) {
	const ctaStyles = {
		poisonControlCTA: styles.poisonControlCTA,
		hidden: styles.hidden,
	};

	const cx = classNames.bind(ctaStyles);

	const ctaClasses = cx({
		poisonControlCTA: true,
		hidden: poisonSeverity > 0 ? false : true,
	});

	return (
		<a href="tel:+1-888-426-4435" className={ctaClasses}>
			Call ASPCA Animal Poison Control
		</a>
	);
}

export default PosionControlCTA;
