function trapModalFocus() {
	// get focusable elements
	let focusableElements =
		'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	// get the modal to trap focus
	let modal = document.querySelector(".modal.open");
	// get all focusable elements in the modal
	let focusableElementsInModal = modal.querySelectorAll(focusableElements);
	// get the first focusable element in the modal
	let firstFocusable = focusableElementsInModal[0];
	// get the last focusable element in the modal
	let lastFocusable =
		focusableElementsInModal[focusableElementsInModal.length - 1];

	// handle tabbing
	modal.addEventListener("keydown", (e) => {
		// if any key other than tab is pressed
		if (e.key !== "Tab") {
			return;
		}
		// when tabbing backward with SHIFT + TAB
		if (e.shiftKey) {
			// if the first focusable element is in focus
			if (document.activeElement === firstFocusable) {
				// focus the last focusable element
				lastFocusable.focus();
				// prevent default behavior
				e.preventDefault();
			}
		} else {
			// when tabbing forward with TAB
			// if the last focusable element is in focus
			if (document.activeElement === lastFocusable) {
				// focus the first focusable element
				firstFocusable.focus();
				// prevent default behavior
				e.preventDefault();
			}
		}
	});
	// focus on the first element in the modal
	firstFocusable.focus();
}

export default trapModalFocus;

/* Implementation

  import trapModalFocus from "./functions/trapModalFocus";

  trapModalFocus();
*/
