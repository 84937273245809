import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/SideBySide.module.scss";
const classNames = require("classnames/bind");

SideBySide.propTypes = {
  source: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  content: PropTypes.string,
  destination: PropTypes.string,
  hrefContent: PropTypes.string,
  blank: PropTypes.bool,
  classModifier: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

function SideBySide({
  source,
  imgAlt,
  content,
  destination,
  hrefContent,
  blank,
  classModifier,
  ariaLabel,
}) {
  //handles class modifier
  if (!classModifier) {
    classModifier = "";
    return;
  }
  classModifier = "-" + classModifier;

  let textClass = "text" + classModifier;
  let hrefClass = "href" + classModifier;
  let imgClass = "img" + classModifier;

  const classStyles = {
    container: styles.container,
    containerCustom: styles["container" + classModifier],
  };

  const cx = classNames.bind(classStyles);

  const containerClass = cx({
    container: true,
    containerCustom: true,
  });

  return (
    <div className={containerClass}>
      <img className={styles[imgClass]} src={source} alt={"null"} />

      {hrefContent ? (
        <p className={styles[textClass]}>
          {content}
          <a
            className={styles[hrefClass] + " gtm-link-external"}
            href={destination}
            target={blank ? "_blank" : null}
            rel="noreferrer"
            aria-label={ariaLabel}
            data-gtm-event-label={hrefContent}
          >
            {hrefContent}
          </a>
        </p>
      ) : (
        <p className={styles[textClass]}>{content}</p>
      )}
    </div>
  );
}

export default SideBySide;
