import React, { useEffect, useRef, useState } from "react";
import Nav from "./components/Nav";
import HumanFoodList from "./components/HumanFoodList";
import PoisonSignList from "./components/PoisonSignList";
import TopDogPoisonsList from "./components/TopDogPoisonsList";
import ButtonCTA from "./components/ButtonCTA";
import SocialCallouts from "./components/SocialCallouts";
import PartnerLogoList from "./components/PartnerLogoList";
import Sources from "./components/Sources";
import Footer from "./components/Footer";
import styles from "./styles/App.module.scss";
import PoisonControl from "./components/PoisonControl";
import DogPoisonStats from "./components/DogPoisonStats";
import ClipPathCSS from "./components/ClipPathCSS";
import Gif from "./components/Gif";
import ScannerModal from "./components/ScannerModal";
import DisclaimerModal from "./components/DisclaimerModal";
import OfflineModal from "./components/OfflineModal";
import ContributorModal from "./components/ContributorModal";
import trapModalFocus from "./functions/trapModalFocus";
// animation webm & gif file paths
import headlineGif from "./media/gif/animation-headline-5s.gif";
import tongueGif from "./media/gif/animation-tongue-5s.gif";
import poisonGif from "./media/gif/animation-poison-5s.gif";
import grapeGif from "./media/gif/animation-grape-5s.gif";
import pillGif from "./media/gif/animation-pill-5s.gif";
import dogGif from "./media/gif/animation-dog-5s.gif";
import catGif from "./media/gif/animation-cat-5s.gif";
import dogsEatEverythingVideo from "./media/video/dogs-eat-everything.mov";
import dogsEatEverythingPoster from "./media/video/video-cover-image.png";
import Video from "./components/Video";
import CookieModal from "./components/CookieModal";

import dogSafetyCard from "./media/pdfs/dog-safety-card.pdf";

function App() {
  //refs used to measure DOM element position for  nav
  const sectionRefs = {
    homeRef: useRef(),
    eatRef: useRef(),
    poisonRef: useRef(),
    socialMediaRef: useRef(),
  };

  const chatRef = useRef();

  const [cookieModalActive, setCookieModalActive] = useState(false);
  const [scannerActive, setScannerActive] = useState(false);
  const [disclaimerActive, setDisclaimerActive] = useState(false);
  const [offlineActive, setOfflineActive] = useState(false);
  const [modalContent, setModalContent] = useState("LandingContent");
  const [targetModal, setTargetModal] = useState("");
  const [isContribModalOpen, setIsContribModalOpen] = useState(false);

  function handleChatOverlay() {
    setTargetModal("chatbot");
    setDisclaimerActive(true);
  }

  function resetDefaultScrolling() {
    document.body.style.overflow = "auto";
    document.documentElement.style.overflow = "auto";
  }

  useEffect(() => {
    let openModal = document.querySelector(".modal.open");
    if (openModal) {
      trapModalFocus();
    }
  }, [
    cookieModalActive,
    offlineActive,
    scannerActive,
    disclaimerActive,
    modalContent,
    isContribModalOpen,
  ]);

  return (
    <div className={styles.App}>
      <CookieModal
        cookieModalActive={cookieModalActive}
        setCookieModalActive={setCookieModalActive}
        resetDefaultScrolling={resetDefaultScrolling}
      />
      <OfflineModal
        offlineActive={offlineActive}
        setOfflineActive={setOfflineActive}
        resetDefaultScrolling={resetDefaultScrolling}
      />
      <ContributorModal
        isContribModalOpen={isContribModalOpen}
        setIsContribModalOpen={setIsContribModalOpen}
      />
      <header>
        <Nav
          sectionRefs={sectionRefs}
          scannerActive={scannerActive}
          setDisclaimerActive={setDisclaimerActive}
          setOfflineActive={setOfflineActive}
          setTargetModal={setTargetModal}
        />

        {navigator.onLine ? (
          <button
            type="button"
            ref={chatRef}
            className={styles.chatButton + " gtm-link-internal"}
            onClick={handleChatOverlay}
            aria-label={"Open chat bot application."}
            data-gtm-event-label="Need Help?"
          ></button>
        ) : null}
      </header>

      <main id={"main"}>
        <h1 className={"visually-hidden"}>Dogs Eat Everything</h1>
        <section
          id="poison-control"
          className={styles.poisonControl}
          ref={sectionRefs.homeRef}
        >
          <ClipPathCSS
            mobilePath={"M 0,0.3% L 0,1% L 1,1% L 1,0.95% Q 0.4,1.1% 0,0.3% Z"}
            desktopPath={
              "M 0,0.3% L 0,1% L 1,1% L 1,0.75% Q 0.7,1.2% 0.2,0.3% Z"
            }
            uniqueClass={"poisonControlClip"}
          />

          <div className={"container-fluid"}>
            <PoisonControl />
          </div>
        </section>

        <section id="home" className={styles.home}>
          <div className={"container " + styles.homeContainer}>
            <Gif
              classProp={styles.headlineAnimation}
              gifSrc={headlineGif}
              altText={"Dogs Eat Everything"}
            />
            <div className={styles.headlineContent}>
              <p className={"mt-0"}>
                Everyone knows dogs eat crazy things. But did you know thousands
                of dogs are accidentally poisoned each year because of something
                they ate?<sup>1</sup>
              </p>
            </div>
          </div>
          <div className={"container-fluid " + styles.videoContent}>
            <div className={styles.videoContainer}>
              <Video
                videoSrc={dogsEatEverythingVideo}
                posterSrc={dogsEatEverythingPoster}
                captionsSrc={"/video/dogs-eat-everything-video-cc.vtt"}
              />
              <p className={"container bold mt-2"}>
                Most pooch poisonings are preventable. Learn what harmful things
                might be lurking in your home.
              </p>
            </div>
          </div>
          <DogPoisonStats />
          <ClipPathCSS
            mobilePath={"M 0,1% L 0,0 L 1%,0 Q .25%,0 0,1% L 1%,1% Z"}
            desktopPath={"M 0,0 Q 0.25%,1% 1%,0.6% L 1%,0 Z"}
            uniqueClass={"introBottomCurve"}
          />
        </section>

        <section id="why-dogs-eat" className={styles.whyDogsEat}>
          <div className={styles.dogsEatContainer}>
            <Gif
              classProp={styles.tongueAnimation}
              gifSrc={tongueGif}
              altText={"A dog pulling items into its mouth with its tongue."}
            />
          </div>

          <div className={"container " + styles.whyDogsEatContainer}>
            <h2 className={"dropShadow"}>
              But first…why do dogs eat everything?
            </h2>
            <p>
              They use their mouths to explore. It’s natural for them to eat
              things (no matter how gross!), but dogs don’t always know what’s
              good or bad. They need our help to keep them safe.
            </p>
          </div>
        </section>

        <section id="top-poisons" className={styles.topPoisons}>
          <ClipPathCSS
            mobilePath={"M 0,0 L 1%,0 L 1%,0.2% Q 0.75%,-0.2% 0,1% Z"}
            desktopPath={
              "M 0,0 L 0,0.2% Q 0.15%,1% 0.6%,0.5% S 0.9%,0 1%,1% L 1%,0 Z"
            }
            uniqueClass={"topPoisonsTopCurve"}
            desktopBackgroundColor={"#fd4789"}
          />
          <div className={"container-fluid"}>
            <Gif
              classProp={styles.poisonAnimation}
              gifSrc={poisonGif}
              altText={"A dog licking a poisoned rat."}
            />
          </div>

          <div className={"container " + styles.topDogContainer}>
            <h2>
              What are the top dog poisons?<sup>1</sup>
            </h2>
            <TopDogPoisonsList />
          </div>
          <Gif
            classProp={styles.grapeAnimation}
            gifSrc={grapeGif}
            altText={"A dog getting sick after eating grapes."}
          />
        </section>

        <section
          id="human-food"
          className={styles.humanFood}
          ref={sectionRefs.eatRef}
        >
          <div id="human-food-anchor" className={styles.navAnchor}></div>
          <ClipPathCSS
            mobilePath={"M 0,0.5% L 0,1% L 1%,1% Q 0.25%,0.1% 0,0.8% Z"}
            desktopPath={
              "M 0,0.6% Q 0.25%,1% 0.5%,0.5% S 0.75%,0 1%,0.5% L 1%,1% L 0,1%  Z"
            }
            uniqueClass={"humanFoodTopCurve"}
          />
          <div
            id="human-food"
            className={"container " + styles.humanFoodContainer}
          >
            <h2>Human food can be bad for dogs</h2>
            <div>
              <p className={styles.subheadTextHumanFood}>
                No matter how much they beg for it, these human foods are not
                safe for dogs.
                <span className={"bold"}>
                  {" Click each item to learn what harm it can cause."}
                  <sup className={"sup-left"}>3</sup>
                </span>
              </p>
            </div>
          </div>
          <ClipPathCSS
            mobilePath={"M 0,0 L 0,0.6% C 0.7%,3% 0.35%,0.3% 1%,0 Z"}
            desktopPath={
              "M 0,0 L 0,0.1% Q 0.25%,1% 0.5%,0.5% S 0.75%,0 1%,1% L 1%,0  Z"
            }
            uniqueClass={"humanFoodBottomCurve"}
          />
        </section>

        <section id="human-food-list" className={styles.humanFoodList}>
          <div className={"container"}>
            <HumanFoodList />
          </div>
        </section>

        <section
          id="poison-signs"
          className={styles.signsOfPoison}
          ref={sectionRefs.poisonRef}
        >
          <div id="signs-of-poison-anchor" className={styles.navAnchor}></div>
          <ClipPathCSS
            mobilePath={"M 0,0.7% L 0,1% L 1%,1% L 1%,0 Q 0.75%,1.4% 0,0.7% Z"}
            desktopPath={
              "M 0,0 Q 0.25%,1% 0.5%,0.5% S 0.75%,0 1%,0.4% L 1%,1% L 0,1% Z"
            }
            uniqueClass={"poisonSignTopCurve"}
          />
          <div className={"container-fluid"}>
            <Gif
              classProp={styles.pillAnimation}
              gifSrc={pillGif}
              altText={"A dog emptying a bottle of prescription pills."}
            />
          </div>

          <div className={"container"}>
            <h2>Signs of possible pooch poisoning</h2>
            <p className={styles.subheadTextPoisoning}>
              If you spot any of these symptoms, stay calm but act fast.
              <sup>4</sup>
            </p>
            <PoisonSignList />
          </div>
          <ClipPathCSS
            mobilePath={
              "M 0,0.2% Q 0.3%,1% 0.5%,0.5% S 0.75%,0 1%,1% L 1%,0 L 0,0 Z"
            }
            desktopPath={"M 0,1% Q 0.5%,0 1%,1% L 1%,0 L 0,0 Z"}
            uniqueClass={"poisonSignBottomCurve"}
          />
        </section>

        <section id="safety-card" className={styles.safetyCard}>
          <Gif
            classProp={styles.dogAnimation}
            gifSrc={dogGif}
            altText={"A dizzy dog falling over."}
          />
          <div className={"container " + styles.safetyCardContainer}>
            <ClipPathCSS
              mobilePath={
                "M 0,0 L 0,1% L 1%,1% L 1%,0.97% C 0.8%,0 0.7%,0 0.5%,0.5% S 0.25%,1% 0,0 Z"
              }
              desktopPath={
                "M 0,0.9% Q 0.2%,-0.2% 0.5%,0.3% S 0.75%,1% 1%,0.2% L 1%,1% L 0,1% Z"
              }
              uniqueClass={"dogSafetyCardTopCurve"}
            />
            <div className={styles.safetyContent}>
              <h2>Dog Safety Card</h2>
              <p className={styles.subheadTextDownload}>
                Download, customize, and print out a safety card to keep handy
                in case of an accidental poisoning.
              </p>
              <ButtonCTA
                buttonText={"Download Dog Safety Card"}
                location={dogSafetyCard}
                iconName={"download"}
                gtmLabel={"Download Dog Safety Card"}
              />
            </div>
            <Gif
              classProp={styles.catAnimation}
              gifSrc={catGif}
              altText={"A dog eating out of a cat's litter box."}
            />
            <ClipPathCSS
              mobilePath={
                "M 0,0 L 1%,0 L 1%,1% C 0.8%,0 0.7%,0 0.40%,0.5% S -0.2%,-0.5% 0,0 Z"
              }
              desktopPath={
                "M 0,0 Q 0.25%,1% 0.5%,0.5% S 0.75%,0 1%,0.5% L 1%,0 Z"
              }
              uniqueClass={"dogSafetyCardBottomCurve"}
            />
          </div>
        </section>

        <section
          id="social-media"
          className={styles.socialMedia}
          ref={sectionRefs.socialMediaRef}
        >
          <div className={"container-fluid"}>
            <SocialCallouts />
          </div>
        </section>

        <section id="partners-sources" className={styles.partnersSources}>
          <ClipPathCSS
            mobilePath={"M 0,0 L 0,1% L 1%,1% L 1%,0.3% Q 0.6%,1.4% 0,0 Z"}
            desktopPath={"M 0,0.8%  Q 0.2%,-0.2% 1%,1% L 0,1% Z"}
            uniqueClass={"partnershipTopCurve"}
          />
          <div className={styles.partnershipContainer + " container"}>
            <PartnerLogoList />
          </div>
          <div className={"container-fluid"}>
            <Sources />
          </div>
        </section>
        <DisclaimerModal
          disclaimerActive={disclaimerActive}
          setDisclaimerActive={setDisclaimerActive}
          chatRef={chatRef}
          setScannerActive={setScannerActive}
          resetDefaultScrolling={resetDefaultScrolling}
          targetModal={targetModal}
        />
        <ScannerModal
          modalId={"poison-modal"}
          scannerActive={scannerActive}
          setScannerActive={setScannerActive}
          resetDefaultScrolling={resetDefaultScrolling}
          modalContent={modalContent}
          setModalContent={setModalContent}
        />
      </main>
      <Footer />
    </div>
  );
}

export default App;
