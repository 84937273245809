import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../styles/ContributorModal.module.scss";
import getQueryString from "../functions/queryStringHandling";
const {
	stopDefaultScrolling,
	resetDefaultScrolling,
} = require("../functions/scrollHandling");
const classNames = require("classnames/bind");

ContributorModal.propTypes = {
	isContribModalOpen: PropTypes.bool.isRequired,
	setIsContribModalOpen: PropTypes.func.isRequired,
};

function ContributorModal({ isContribModalOpen, setIsContribModalOpen }) {
	const classStyles = {
		modal: styles.modal,
		active: styles.active,
		underlay: styles.underlay,
		staticModal: "modal",
		open: "open",
	};

	const cx = classNames.bind(classStyles);

	const modalClasses = cx({
		modal: true,
		active: isContribModalOpen,
		staticModal: true,
		open: isContribModalOpen,
	});

	const underlayClass = cx({
		underlay: isContribModalOpen,
	});

	const handleClick = () => {
		setIsContribModalOpen(false);
		resetDefaultScrolling();
	};

	useEffect(() => {
		let queryStr = getQueryString("whoLetTheDogsOut");
		if (queryStr === "me") {
			setIsContribModalOpen(true);
			stopDefaultScrolling();
		}
	}, [setIsContribModalOpen]);

	return (
		<>
			<div className={modalClasses}>
				<div className={styles.devList}>
					<p className={styles.header}>Developement Team:</p>
					<p className={styles.developer}>Matthew Jacome</p>
					<p className={styles.developer}>Luther Riggs</p>
					<p className={styles.developer}>Kevin McLaughlin</p>
					<p className={styles.developer}>Ryan Savage</p>
					<div className={styles.buttonContainer}>
						<button
							type="button"
							className={"close-btn"}
							onClick={handleClick}
							aria-label={"Close Contributor modal"}
						>
							Close
						</button>
					</div>
				</div>
			</div>
			<div className={underlayClass}></div>
		</>
	);
}

export default ContributorModal;
