import React, { useEffect, useRef } from "react";
import styles from "../styles/DogPoisonStats.module.scss";
const classNames = require("classnames/bind");

function DogPoisonStats() {
  const circleRefs = {
    circleOneRef: useRef(),
    circleTwoRef: useRef(),
    circleThreeRef: useRef(),
  };
  const elementRef = useRef();

  useEffect(() => {
    function handleScroll() {
      const parentTop = elementRef.current.offsetTop;
      const mobileAdjust = 2;
      const desktopAdjust = 1.5;
      const modifier = window.innerWidth > 776 ? desktopAdjust : mobileAdjust;
      for (const circle in circleRefs) {
        const circleTop = circleRefs[circle].current.offsetTop;
        const targetPosition =
          window.scrollY - (parentTop + circleTop) / modifier;
        if (targetPosition >= 0) {
          circleRefs[circle].current.classList.add(`${styles.active}`);
        }
      }
    }
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  });

  const classStyles = {
    active: styles.active,
    circle: styles.circle,
    circleOne: styles.circleOne,
    circleTwo: styles.circleTwo,
    circleThree: styles.circleThree,
  };

  const cx = classNames.bind(classStyles);

  const circleOneClass = cx({
    circle: true,
    circleOne: true,
  });

  const circleTwoClass = cx({
    circle: true,
    circleTwo: true,
  });

  const circleThreeClass = cx({
    circle: true,
    circleThree: true,
  });

  return (
    <div className={styles.container} ref={elementRef}>
      <div className={"visually-hidden"}>
        <p>
          More than 100,000 pets are accidentally poisoned every year
          <sup>2</sup>
        </p>
        <p>
          The #1 dog toxin is OTC medication.<sup>1</sup>
        </p>
        <p>
          Every 15 minutes a dog is poisoned by chocolate<sup>1</sup>
        </p>
      </div>
      <div className={styles.circlesWrapper} ref={elementRef}>
        <div
          id="info-circle-one"
          className={circleOneClass}
          ref={circleRefs.circleOneRef}
        >
          <p className={styles.text} aria-hidden={"true"}>
            More than<br></br> <strong className={styles.textStrong}>100,000<br></br></strong>pets are<br></br>accidentally <br></br>
            poisoned<br></br>
            <span className={styles.lineWrap}>
              every year<sup className={styles.superscript}>2</sup>
            </span>
          </p>
        </div>
        <div
          id="info-circle-two"
          className={circleTwoClass}
          ref={circleRefs.circleTwoRef}
        >
          <p className={styles.text} aria-hidden={"true"}>
            <span className={styles.lineWrap}>The<br></br></span>
            <span className={styles.textStrong}>#1 dog<br></br>toxin<br></br></span>
            <span className={styles.lineWrap}>is OTC <br></br>medication<sup className={styles.superscript}>1</sup></span>
          </p>
        </div>
        <div
          id="info-circle-three"
          className={circleThreeClass}
          ref={circleRefs.circleThreeRef}
        >
          <p className={styles.text} aria-hidden={"true"}>
            <span className="adjust-right-sm">Every </span>
            <strong className={styles.textStrong}>
              <span className="adjust-right-sm">15<br></br></span>Minutes
            </strong>{" "}
            a dog is <span className={styles.lineWrap}>poisoned by</span>
            {" chocolate"}
            <sup className={styles.superscript}>1</sup>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DogPoisonStats;
