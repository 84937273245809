import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../styles/ModalLandingContent.module.scss";
import ScannerButton from "./ScannerButton";
const classNames = require("classnames/bind");

ModalLandingContent.propTypes = {
	modalContent: PropTypes.string.isRequired,
	setModalContent: PropTypes.func.isRequired,
	setUploadedImage: PropTypes.func.isRequired,
	setPoisonSeverity: PropTypes.func.isRequired,
	uploadedImage: PropTypes.string.isRequired,
};

function ModalLandingContent({
	modalContent,
	setModalContent,
	setUploadedImage,
	setPoisonSeverity,
	uploadedImage,
}) {
	const classStyles = {
		wrapper: styles.wrapper,
		collapse: styles.collapse,
	};
	const cx = classNames.bind(classStyles);

	const wrapperClass = cx({
		wrapper: true,
		collapse: modalContent !== "LandingContent" ? true : false,
	});

	useEffect(() => {
		if (uploadedImage !== "empty") {
			setModalContent("poisonScanner");
		}
	}, [uploadedImage, setModalContent]);

	return (
		<div className={wrapperClass}>
			<div className={"container"}>
				<h3>Not sure what your dog ate?</h3>
				<p className={styles.modalText}>
					Use the poison scanner to take a picture of what your dog ate, and
					we’ll do the rest!
				</p>
				<ScannerButton
					setUploadedImage={setUploadedImage}
					setPoisonSeverity={setPoisonSeverity}
				/>
			</div>
		</div>
	);
}

export default ModalLandingContent;
