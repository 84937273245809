import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/PoisonSign.module.scss";

PoisonSign.propTypes = {
	data: PropTypes.object.isRequired,
};

function PoisonSign({ data }) {
	return (
		<div className={styles.wrapper}>
			<img className={styles.image} src={data.img} alt={"null"} />
			<p className={styles.text}>{data.name}</p>
		</div>
	);
}

export default PoisonSign;
