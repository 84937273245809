import React, { useState, useEffect } from "react";
import styles from "../styles/HumanFoodList.module.scss";
import { harmfulFoodJSON } from "../data.js";
import SlidingToggle from "./SlidingToggle.js";

function HumanFood() {
  const [keyboardActive, setKeyboardActive] = useState(false);

  useEffect(() => {
    function handleKeyboard(e) {
      const tabKeyCode = 9;
      if (e.keyCode === tabKeyCode) {
        setKeyboardActive(true);
      }
    }
    document.addEventListener("keydown", handleKeyboard);

    return () => document.removeEventListener("keydown", handleKeyboard);
  });

  let listLength = harmfulFoodJSON.length;
  const harmfulFoodList1 = harmfulFoodJSON.map((food, index) =>
    index < listLength / 2 ? (
      <SlidingToggle
        data={food}
        key={food.id}
        keyboardActive={keyboardActive}
        aria-label={harmfulFoodJSON.data}
      />
    ) : (
      ""
    )
  );
  const harmfulFoodList2 = harmfulFoodJSON.map((food, index) =>
    index >= listLength / 2 ? (
      <SlidingToggle
        data={food}
        key={food.id}
        keyboardActive={keyboardActive}
        aria-label={harmfulFoodJSON.data}
      />
    ) : (
      ""
    )
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>{harmfulFoodList1}</div>
      <div className={styles.list}>{harmfulFoodList2}</div>
    </div>
  );
}

export default HumanFood;
