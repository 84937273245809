import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Poison.module.scss";
const classNames = require("classnames/bind");

Poison.propTypes = {
  data: PropTypes.object.isRequired,
  activePoison: PropTypes.string.isRequired,
  setActivePoison: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  getLaunchCoordinates: PropTypes.func.isRequired,
  coordinates: PropTypes.object.isRequired,
};

function Poison({
  data,
  activePoison,
  setActivePoison,
  setHeader,
  setDescription,
  getLaunchCoordinates,
  coordinates,
}) {
  const ariaLabel = `${data.activeName}: ${data.description}`;

  const launchSite = useRef();
  const icon = useRef();

  const [iconClicked, setIconClicked] = useState(false);
  const [gtmLabel, setGtmLabel] = useState(data.name);

  function handleClick() {
    setIconClicked(!iconClicked);
    handleGTM();
    // if the current icon is already active, reset the following state values
    if (activePoison === data.name) {
      setActivePoison("");
      setHeader("Pick your poison below to learn more.");
      setDescription("");
      return;
    }
    // pass back to parent component the name of clicked poison to update activePoison state
    setActivePoison(data.name);
    setHeader(data.activeName);
    setDescription(data.description);
    // pass back to parent component the coordinates of the clicked icon, in relation to viewport top/left
    getLaunchCoordinates(launchSite);
    // toggle active class to set red circle/slash styling and absolute positioning for the icon
  }

  function handleGTM() {
    if (!iconClicked) {
      setGtmLabel(data.name + " close");
      return;
    }
    setGtmLabel(data.name);
  }

  useEffect(() => {
    function launchIcon(coordinates) {
      if (activePoison === data.name) {
        // calculate vectors to target (X,Y)
        let vectorToTargetTop =
          coordinates.targetTopFromViewport - coordinates.launchTopFromViewport;
        let vectorToTargetLeft =
          coordinates.targetLeftFromViewport -
          coordinates.launchLeftFromViewport;
        // set the vector amounts, inline
        icon.current.style.top = `${vectorToTargetTop}px`;
        icon.current.style.left = `${vectorToTargetLeft}px`;
        return;
      }
      // return to launchSite (0,0)
      icon.current.style.top = 0;
      icon.current.style.left = 0;
    }

    launchIcon(coordinates);
  }, [activePoison, data.name, coordinates]);

  const classStyles = {
    launchButton: styles.launchButton,
    icon: styles.icon,
    text: styles.text,
    active: styles.active,
  };

  const cx = classNames.bind(classStyles);
  const buttonClass = cx({
    launchButton: true,
    active: activePoison === data.name ? true : false,
  });

  const poisonTextClass = cx({
    text: true,
  });

  const iconClass = cx({
    icon: true,
    active: activePoison === data.name ? true : false,
  });

  // set inline background-image style per component render
  const bgImgURL = { backgroundImage: `url(${data.img})` };

  return (
    <button
      className={buttonClass + " gtm-link-internal"}
      type={"button"}
      onClick={handleClick}
      aria-label={ariaLabel}
      data-gtm-event-label={gtmLabel}
    >
      <div className={styles.iconWrapper} ref={launchSite}>
        <div className={iconClass} style={bgImgURL} ref={icon}></div>
      </div>
      <p className={poisonTextClass}>{data.name}</p>
    </button>
  );
}

export default Poison;
