// poisonsJSON image imports
import iconPoisonPillBottle from "./media/images/poisons/icon-poison-pill-bottle.svg";
import iconPoisonPill from "./media/images/poisons/icon-poison-pill.svg";
import iconPoisonPillCupcake from "./media/images/poisons/icon-poison-cupcake.svg";
import iconPoisonRodent from "./media/images/poisons/icon-poison-rodent.svg";
import iconPoisonGarden from "./media/images/poisons/icon-poison-garden.svg";
import iconPoisonPlant from "./media/images/poisons/icon-poison-plant.svg";
import iconPoisonCleaner from "./media/images/poisons/icon-poison-cleaner.svg";
import iconPoisonMedicine from "./media/images/poisons/icon-poison-medicine.svg";
import iconPoisonDetergent from "./media/images/poisons/icon-poison-detergent.svg";
import iconPoisonGrapes from "./media/images/poisons/icon-poison-grapes.svg";

// poisonSignsJSON image imports
import iconFoodOnions from "./media/images/harmful-foods/icon-food-onions.svg";
import iconFoodAlcohol from "./media/images/harmful-foods/icon-food-alcohol.svg";
import iconFoodNuts from "./media/images/harmful-foods/icon-food-nuts.svg";
import iconFoodTomato from "./media/images/harmful-foods/icon-food-tomato.svg";
import iconFoodGrapes from "./media/images/harmful-foods/icon-food-grapes.svg";
import iconFoodCoffee from "./media/images/harmful-foods/icon-food-coffee.svg";
import iconFoodCannabis from "./media/images/harmful-foods/icon-food-cannabis.svg";
import iconFoodMeat from "./media/images/harmful-foods/icon-food-meat.svg";
import iconFoodYeast from "./media/images/harmful-foods/icon-food-yeast.svg";
import iconFoodDairy from "./media/images/harmful-foods/icon-food-dairy.svg";
import iconFoodStoneFruit from "./media/images/harmful-foods/icon-food-stone-fruit.svg";
import iconFoodGum from "./media/images/harmful-foods/icon-food-gum.svg";

// poisonSignsJSON image imports
import iconDogVomit from "./media/images/signs/icon-dog-vomit.svg";
import iconDogDrooling from "./media/images/signs/icon-dog-drooling.svg";
import iconDogDiarrhea from "./media/images/signs/icon-dog-diarrhea.svg";
import iconDogUrination from "./media/images/signs/icon-dog-urination.svg";
import iconDogShaking from "./media/images/signs/icon-dog-shaking.svg";
import iconDogCollapse from "./media/images/signs/icon-dog-collapse.svg";
import iconDogSleeping from "./media/images/signs/icon-dog-sleeping.svg";
import iconDogUnusual from "./media/images/signs/icon-dog-unusual.svg";

// partnerLogoJson image imports
import logoPartnerZoomies from "./media/images/partners/logo-partner-zoomies.jpeg";
import logoPartnerRescueCity from "./media/images/partners/logo-partner-rescue-city.png";
import logoPartnerASPCA from "./media/images/partners/logo-partner-aspca.jpeg";

export const poisonsJSON = [
	{
		id: 1,
		name: "Over-the-counter meds",
		activeName: "Over-the-counter meds",
		img: iconPoisonPillBottle,
		description:
			"Ibuprofen, acetaminophen, cold and flu medicine, vitamins and supplements, joint cream",
	},
	{
		id: 2,
		name: "Animal medications",
		activeName: "Animal medications",
		img: iconPoisonPill,
		description: "Keep any pills, ointments, and chewables out of paw's reach",
	},
	{
		id: 3,
		name: "Chocolate",
		activeName: "Chocolate",
		img: iconPoisonPillCupcake,
		description: "The darker the chocolate, the worse for Fido",
	},
	{
		id: 4,
		name: "Rodent killers",
		activeName: "Rodent killers",
		img: iconPoisonRodent,
		description:
			"If it's poisonous to rats and mice, it may be poisonous to dogs, too",
	},
	{
		id: 5,
		name: "Garden products",
		activeName: "Garden products",
		img: iconPoisonGarden,
		description: "Fertilizers, herbicides, fungicides",
	},
	{
		id: 6,
		name: "Plants",
		activeName: "Plants",
		img: iconPoisonPlant,
		description:
			"Aloe vera, sago palm, tomato plant, ivy, amaryllis, begonia, and gladiola, to name a few",
	},
	{
		id: 7,
		name: "Bug killers",
		activeName: "Bug killers",
		img: iconPoisonCleaner,
		description: "Ant baits, bug sprays, flea and tick repellent",
	},
	{
		id: 8,
		name: "Human prescription meds",
		activeName: "Human prescription medications",
		img: iconPoisonMedicine,
		description:
			"Antidepressants, birth control pills, cardiac medications, ointments",
	},
	{
		id: 9,
		name: "Household toxins",
		activeName: "Household toxins",
		img: iconPoisonDetergent,
		description:
			"Paint, adhesives, spackle, and home cleaning products are all hazardous",
	},
	{
		id: 10,
		name: "Human food",
		activeName: "Human food",
		img: iconPoisonGrapes,
		description:
			"Grapes, raisins, onions, garlic, and xylitol are the most common, but see below for more",
	},
];

export const harmfulFoodJSON = [
	{
		id: 1,
		name: "Onions, garlic, chives",
		description: "Upset stomach, red blood cell damage, anemia",
		img: iconFoodOnions,
	},
	{
		id: 2,
		name: "Alcoholic beverages",
		description: "Highly toxic! Vomiting, diarrhea, tremors, coma, even death",
		img: iconFoodAlcohol,
	},
	{
		id: 3,
		name: "Nuts, especially macadamia nuts",
		description:
			"Vomiting, diarrhea, weakness, depression, tremors, hyperthermia, pancreatitis",
		img: iconFoodNuts,
	},
	{
		id: 4,
		name: "Unripe tomatoes",
		description: "Unripe tomatoes, leaves, and stems may cause vomiting, diarrhea, weakness, and confusion",
		img: iconFoodTomato,
	},
	{
		id: 5,
		name: "Grapes and raisins",
		description:
			"Highly toxic! Severe vomiting, diarrhea, abdominal pain, lack of appetite, kidney failure",
		img: iconFoodGrapes,
	},
	{
		id: 6,
		name: "Chocolate and coffee",
		description:
			"Highly toxic! Vomiting, diarrhea, hyperactivity, elevated heart rate, tremors, seizures, even death",
		img: iconFoodCoffee,
	},
	{
		id: 7,
		name: "Marijuana and edibles",
		description: "Dizziness, vomiting, drooling, hyperactivity, coma",
		img: iconFoodCannabis,
	},
	{
		id: 8,
		name: "Raw meat",
		description:
			"May contain harmful bacteria; bones can splinter and puncture organs or cause choking",
		img: iconFoodMeat,
	},
	{
		id: 9,
		name: "Yeast, raw bread dough",
		description: "Highly toxic! Vomiting, diarrhea, tremors, coma, even death",
		img: iconFoodYeast,
	},
	{
		id: 10,
		name: "Milk and cheese",
		description: "Gas, diarrhea, pain in digestive tract",
		img: iconFoodDairy,
	},
	{
		id: 11,
		name: "Stone fruits such as cherries, apricots, peaches, plums",
		description:
			"Pits may get stuck in digestive tract, cause drooling, difficulty breathing, tremors, seizures, or death",
		img: iconFoodStoneFruit,
	},
	{
		id: 12,
		name: "Sugar-free gum or candy containing Xylitol",
		description: "Vomiting, lethargy, seizures, liver failure",
		img: iconFoodGum,
	},
];

export const poisonSignsJSON = [
	{
		id: 1,
		name: "Vomiting",
		img: iconDogVomit,
	},
	{
		id: 2,
		name: "Excessive drooling",
		img: iconDogDrooling,
	},
	{
		id: 3,
		name: "Diarrhea",
		img: iconDogDiarrhea,
	},
	{
		id: 4,
		name: "Excessive thirst/urination",
		img: iconDogUrination,
	},
	{
		id: 5,
		name: "Lack of coordination",
		img: iconDogShaking,
	},
	{
		id: 6,
		name: "Collapse",
		img: iconDogCollapse,
	},
	{
		id: 7,
		name: "Weakness/lethargy",
		img: iconDogSleeping,
	},
	{
		id: 8,
		name: "Unusual behavior",
		img: iconDogUnusual,
	},
];

export const partnerLogoJson = [
  {
    name: "ASPCA",
    src: logoPartnerASPCA,
    url: "https://www.aspca.org",
  },
	{
		name: "Zoomies NYC",
		src: logoPartnerZoomies,
		url: "http://www.zoomiesnyc.com",
	},
	{
		name: "Rescue City",
		src: logoPartnerRescueCity,
		url: "http://www.rescuecity.nyc",
	},
];
