import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/ButtonCTA.module.scss";
const classNames = require("classnames/bind");

ButtonCTA.propTypes = {
  buttonText: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  callback: PropTypes.func,
  location: PropTypes.string,
  gtmLabel: PropTypes.string,
};

function ButtonCTA({ buttonText, iconName, callback, location, gtmLabel }) {
  const iconStyles = {
    camera: styles.camera,
    download: styles.download,
    // add new icon styles here => iconKey: styles.iconKey
    // then add new icon bg-image to scss file
    hidden: styles.hidden, // default styling
  };
  const iconKey = iconName ? iconName : "hidden";
  const cx = classNames.bind(iconStyles);
  const iconStyleClass = cx({ [iconKey]: true });

  const handleClick = () => {
    // if callback is a string, open the url path in a new window
    if (location) {
      window.open(location, "_blank");
      return;
    }
    // if callback is not a string, run the callback function
    if (callback) {
      callback();
      return;
    }
  };

  return (
    <button
      className={styles.buttonCta + " gtm-pdf"}
      onTouchStart={handleClick}
      onClick={handleClick}
      data-gtm-event-label={gtmLabel}
    >
      {buttonText}
      <i className={iconStyleClass}></i>
    </button>
  );
}

export default ButtonCTA;
