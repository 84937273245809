import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/ButtonClose.module.scss";
const classNames = require("classnames/bind");

ButtonClose.propTypes = {
	setScannerActive: PropTypes.func.isRequired,
	modalContent: PropTypes.string.isRequired,
	setModalContent: PropTypes.func.isRequired,
	setUploadedImage: PropTypes.func.isRequired,
	resetDefaultScrolling: PropTypes.func.isRequired,
};

function ButtonClose({
	setScannerActive,
	setModalContent,
	modalContent,
	setUploadedImage,
	resetDefaultScrolling,
}) {
	const closeModal = () => {
		setScannerActive(false);
		resetDefaultScrolling();
		setModalContent("LandingContent");
		setUploadedImage("empty");
	};

	const classStyles = {
		closeButton: styles.closeButton,
		closeBtnStatic: "close-btn",
		theme: styles.theme,
	};
	const cx = classNames.bind(classStyles);

	const buttonClass = cx({
		closeButton: true,
		closeBtnStatic: true,
		theme: modalContent !== "poisonScanner",
	});

	return (
		<button className={buttonClass} onClick={closeModal}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				viewBox="0 0 20 20"
			>
				<path
					id="Icon_ionic-md-close"
					data-name="Icon ionic-md-close"
					d="M27.523,9.523l-2-2-8,8-8-8-2,2,8,8-8,8,2,2,8-8,8,8,2-2-8-8Z"
					transform="translate(-7.523 -7.523)"
					fill="currentColor"
				/>
			</svg>
		</button>
	);
}

export default ButtonClose;
