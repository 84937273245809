import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import trackVideo from "../functions/handleVideoAnalytics";

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  posterSrc: PropTypes.string,
  captionsSrc: PropTypes.string,
};

function Video({ videoSrc, posterSrc, captionsSrc }) {
  const videoRef = useRef();

  useEffect(() => {
    trackVideo(videoRef.current);
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        className="animationVideo"
        loading="lazy"
        controls
        controlsList="nodownload"
        crossOrigin="anonymous"
        aria-label={`Watch the "Dog's Eat Everything" video.`}
        poster={posterSrc}
      >
        <source src={videoSrc} type={"video/mp4"}></source>
        {captionsSrc ? (
          <track kind="subtitles" srcLang="en" src={captionsSrc} default />
        ) : null}
      </video>
    </div>
  );
}

export default Video;
