import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/TopDogPoisonsList.module.scss";
import { poisonsJSON } from "../data.js";
import Poison from "./Poison.js";
import skullIconClosed from "../media/images/poisons/icon-skull-closed.svg";
import skullIconOpen from "../media/images/poisons/icon-skull-open.svg";
const classNames = require("classnames/bind");

function TopDogPoisonsList() {
	const [activePoison, setActivePoison] = useState("");
	const [header, setHeader] = useState("Pick your poison below to learn more.");
	const [description, setDescription] = useState("");
	const [coordinates, setCoordinates] = useState({});

	const crossboneImg = useRef();
	// ref used for targeting where you want an icon to fly to
	const targetArea = useRef();

	const classStyles = {
		hide: styles.hide,
		activeHeader: styles.activeHeader,
	};

	const cx = classNames.bind(classStyles);

	const descriptionClass = cx({
		hide: activePoison === "" ? true : false,
	});

	const headerClass = cx({
		activeHeader: activePoison === "" ? true : false,
	});

	const poisonList = poisonsJSON.map((poison, index) => (
		<Poison
			key={index}
			data={poison}
			activePoison={activePoison}
			setActivePoison={setActivePoison}
			setHeader={setHeader}
			setDescription={setDescription}
			getLaunchCoordinates={getCoordinates}
			coordinates={coordinates}
		/>
	));

	function getCoordinates(ref) {
		// get the top/left coordinates of the clickedIcon
		let launchTopFromViewport = ref.current.getBoundingClientRect().top;
		let launchLeftFromViewport = ref.current.getBoundingClientRect().left;

		// set the top/left coordinates of the iconTarget
		let targetTopFromViewport = targetArea.current.getBoundingClientRect().top;
		let targetLeftFromViewport =
			targetArea.current.getBoundingClientRect().left;

		setCoordinates({
			launchTopFromViewport,
			launchLeftFromViewport,
			targetTopFromViewport,
			targetLeftFromViewport,
		});
	}

	useEffect(() => {
		// update skull/crossbone img
		if (activePoison) {
			crossboneImg.current.src = skullIconOpen;
			return;
		}
		crossboneImg.current.src = skullIconClosed;
	}, [activePoison]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.bgWrapper}>
				<div className={styles.skullContainer}>
					<img
						className={styles.skullImg}
						ref={crossboneImg}
						src={skullIconClosed}
						alt={"Dog poison warning"}
					/>
					<div className={styles.textBox}>
						<h3 className={headerClass}>{header}</h3>
						<p className={descriptionClass}>{description}</p>
					</div>
					<div className={styles.iconTarget} ref={targetArea}></div>
				</div>
			</div>

			<div className={styles.poisonListContainer}>{poisonList}</div>
		</div>
	);
}

export default TopDogPoisonsList;
