export const stopDefaultScrolling = () => {
	document.body.style.overflow = "hidden";
	document.documentElement.style.overflow = "hidden";
};

export const resetDefaultScrolling = () => {
	document.body.style.overflow = "auto";
	document.documentElement.style.overflow = "auto";
};

/* Implementation

  const {
    stopDefaultScrolling,
    resetDefaultScrolling,
  } = require("../functions/scrollHandling");

  stopDefaultScrolling();
  resetDefaultScrolling();

*/
