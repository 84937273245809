import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ButtonBack from "./ButtonBack";
import ButtonClose from "./ButtonClose";
import PoisonScanner from "./PoisonScanner";
import styles from "../styles/ScannerModal.module.scss";
import ModalLandingContent from "./ModalLandingContent";
import ClipPathCSS from "./ClipPathCSS";
import Gif from "./Gif";
import dogGif from "../media/gif/animation-dog-5s.gif";
const classNames = require("classnames/bind");

ScannerModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  scannerActive: PropTypes.bool.isRequired,
  setScannerActive: PropTypes.func.isRequired,
  resetDefaultScrolling: PropTypes.func.isRequired,
  modalContent: PropTypes.string.isRequired,
  setModalContent: PropTypes.func.isRequired,
};

function ScannerModal({
  modalId,
  scannerActive,
  setScannerActive,
  resetDefaultScrolling,
  modalContent,
  setModalContent,
}) {
  const [uploadedImage, setUploadedImage] = useState("empty");
  // leave poisonSeverity undefined to hide message until Cloud Vision API responds with an image description
  const [poisonSeverity, setPoisonSeverity] = useState();
  const classStyles = {
    modal: styles.modal,
    active: styles.active,
    backdrop: styles.backdrop,
    poisonModalCurve: styles.posionModalCurve,
    dogAnimation: styles.dogAnimation,
    modalStatic: "modal",
    open: "open",
  };

  const cx = classNames.bind(classStyles);

  const modalClass = cx({
    modal: true,
    active: scannerActive,
    modalStatic: true,
    open: scannerActive,
  });

  const backDropClass = cx({
    backdrop: scannerActive,
  });

  const pathClass = cx({
    poisonModalCurve: modalContent !== "poisonScanner",
  });

  const animationClass = cx({
    dogAnimation: true,
    active: modalContent !== "poisonScanner",
  });

  const content =
    modalContent === "poisonScanner" ? (
      <PoisonScanner
        uploadedImage={uploadedImage}
        poisonSeverity={poisonSeverity}
      />
    ) : (
      <ModalLandingContent
        modalContent={modalContent}
        setModalContent={setModalContent}
        setUploadedImage={setUploadedImage}
        setPoisonSeverity={setPoisonSeverity}
        uploadedImage={uploadedImage}
      />
    );

  const elementRef = useRef();

  useEffect(() => {
    const targetElement = elementRef.current;
    let style = "M 0,0 L 0,0 L 0,0.78% C 0.7%,1.5% 1.2%,0.3% 1%,0 Z";
    style = style.split(" ");
    for (let i = 0; i < style.length; i++) {
      if (style[i].includes("%")) {
        style[i] = style[i].replace("%", "");
        let arr = style[i].split(",");
        let x = parseFloat(arr[0]);
        let y = parseFloat(arr[1]);
        x = x * targetElement.offsetWidth;
        y = y * targetElement.offsetHeight;
        style[i] = x + "," + y;
      }
    }
    style = style.join(" ");
    targetElement.setAttribute("style", `clip-path: path('${style}')`);
  }, [scannerActive]);

  return (
    <>
      <div className={backDropClass}></div>
      <div id={modalId} className={modalClass}>
        <Gif
          classProp={animationClass}
          gifSrc={dogGif}
          altText={"A dizzy dog falling over."}
        />
        <div className={pathClass} ref={elementRef}></div>
        <div className={styles.modalNav}>
          <ButtonBack
            setModalContent={setModalContent}
            modalContent={modalContent}
            setUploadedImage={setUploadedImage}
          />
          <ButtonClose
            setModalContent={setModalContent}
            modalContent={modalContent}
            setScannerActive={setScannerActive}
            setUploadedImage={setUploadedImage}
            resetDefaultScrolling={resetDefaultScrolling}
          />
        </div>
        {content}
      </div>
    </>
  );
}

export default ScannerModal;
