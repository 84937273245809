import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/OfflineModal.module.scss";
const classNames = require("classnames/bind");

OfflineModal.propTypes = {
	offlineActive: PropTypes.bool.isRequired,
	setOfflineActive: PropTypes.func.isRequired,
	resetDefaultScrolling: PropTypes.func.isRequired,
};

function OfflineModal({
	offlineActive,
	setOfflineActive,
	resetDefaultScrolling,
}) {
	const classStyles = {
		offlineModal: styles.offlineModal,
		active: styles.active,
		underlay: styles.underlay,
		modal: "modal",
		open: "open",
	};

	const cx = classNames.bind(classStyles);

	const offlineClass = cx({
		offlineModal: true,
		active: offlineActive,
		modal: true,
		open: offlineActive,
	});

	const underlayClass = cx({
		underlay: offlineActive,
	});

	function closeModal() {
		setOfflineActive(false);
		resetDefaultScrolling();
	}

	return (
		<>
			<div className={underlayClass}></div>
			<div className={offlineClass}>
				<button
					type="button"
					className={styles.buttonClose}
					onClick={closeModal}
				></button>
				<p className={styles.header}>Oops!</p>
				<p className={styles.text}>
					It looks like you are offline. Please check back once you have
					network!
				</p>
			</div>
		</>
	);
}

export default OfflineModal;
