import React from "react";
import styles from "../styles/DisclaimerModal.module.scss";
import PropTypes from "prop-types";
const classNames = require("classnames/bind");

DisclaimerModal.propTypes = {
	disclaimerActive: PropTypes.bool.isRequired,
	setDisclaimerActive: PropTypes.func.isRequired,
	chatRef: PropTypes.object.isRequired,
	setScannerActive: PropTypes.func.isRequired,
	resetDefaultScrolling: PropTypes.func.isRequired,
	targetModal: PropTypes.string.isRequired,
};

function DisclaimerModal({
	disclaimerActive,
	setDisclaimerActive,
	chatRef,
	setScannerActive,
	resetDefaultScrolling,
	targetModal,
}) {
	const classStyles = {
		disclaimerModal: styles.disclaimerModal,
		active: styles.active,
		underlay: styles.underlay,
		modal: "modal",
		open: "open",
	};

	const cx = classNames.bind(classStyles);
	const disclaimerClass = cx({
		disclaimerModal: true,
		active: disclaimerActive,
		modal: true,
		open: disclaimerActive,
	});

	const underlayClass = cx({
		underlay: disclaimerActive,
	});

	function handleModalCancel() {
		const iframe = document.getElementById("kommunicate-widget-iframe");

		if (iframe) {
			const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
			innerDoc.getElementById("km-chat-widget-close-button").click();
			chatRef.current.style.display = "block";
		}
		setDisclaimerActive(false);
		resetDefaultScrolling();
	}

	function handleModalContinue() {
		setDisclaimerActive(false);
		if (targetModal === "chatbot") {
			openChatBot();
			setScannerActive(false);
			return;
		}
		if (targetModal === "scanner") {
			setScannerActive(true);
			return;
		}
	}

	function openChatBot() {
		const iframe = document.getElementById("kommunicate-widget-iframe");
		const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
		innerDoc.querySelector("#mck-sidebox-launcher a").click();
		chatRef.current.style.display = "none";

		const chatbotCloseBtn = innerDoc.getElementById(
			"km-chat-widget-close-button"
		);
		chatbotCloseBtn.addEventListener("click", function showChatBotTrigger() {
			chatRef.current.style.display = "block";
		});
	}

	return (
		<>
			<div className={underlayClass}></div>
			<div className={disclaimerClass}>
				<p className={styles.disclaimerText}>
					This content is not meant to replace the expert advice of a qualified
					poison specialist or veterinarian. If you think your dog may have
					eaten something toxic, contact your vet or ASPCA Animal Poison Control
					Center immediately.
				</p>
				<div className={styles.buttonContainer}>
					<button
						className={styles.continueBtn + " gtm-link-internal"}
						onClick={handleModalContinue}
						data-gtm-event-label="Got it, continue"
					>
						Got it, continue
					</button>
					<button
						className="gtm-link-internal close-btn"
						onClick={handleModalCancel}
						data-gtm-event-label="Take me back"
					>
						Take me back
					</button>
				</div>
			</div>
		</>
	);
}

export default DisclaimerModal;
