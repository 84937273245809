import React from "react";
import styles from "../styles/Footer.module.scss";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={"container"}>
        <div className={styles.content}>
          <p className={styles.text}>&copy; 2024 All rights reserved.</p>
          <a
            className={styles.link + " gtm-link-external"}
            href="https://www.fcbhealthny.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            data-gtm-event-label="Privacy Policy"
          >
            Privacy Policy
          </a>
          <a
            className={styles.link + " gtm-link-external"}
            href="https://www.fcbhealthny.com/terms-of-use"
            target="_blank"
            rel="noreferrer"
            data-gtm-event-label="Terms and Conditions"
          >
            Terms and Conditions
          </a>
          <a
            className={styles.link + " gtm-link-external"}
            href="https://www.fcbhealthny.com/california-privacy-notice"
            target="_blank"
            rel="noreferrer"
            data-gtm-event-label="California Privacy Policy"
          >
            California Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
