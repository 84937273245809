import React from "react";
import PropTypes from "prop-types";
import ToxicityResult from "./ToxicityResult";
import styles from "../styles/PoisonScanner.module.scss";

PoisonScanner.propTypes = {
	uploadedImage: PropTypes.string.isRequired,
	poisonSeverity: PropTypes.number,
};

function PoisonScanner({ uploadedImage, poisonSeverity }) {
	return (
		<>
			<div className={styles.wrapper}>
				<div className={"container"}>
					<h3>Poison Scanner</h3>
					<div className={styles.photoFrame}>
						{uploadedImage !== "empty" ? (
							<img src={uploadedImage} alt="user upload"></img>
						) : null}
					</div>

					<ToxicityResult poisonSeverity={poisonSeverity} />
				</div>

				<div className={styles.sources}>
					<p>
						SOURCES: American College of Veterinary Pharmacists, ASPCA Animal
						Poison Control, and Pet Poison Helpline.
					</p>
					<p>
						Under no circumstances is this website meant to replace the expert
						advice of a qualified poison specialist or veterinarian. In the
						event of a poison emergency, contact a vet or poison center
						immediately.
					</p>
				</div>
			</div>
		</>
	);
}

export default PoisonScanner;
