import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/ToxicityResult.module.scss";
import PosionControlCTA from "./PoisonControlCTA";
const classNames = require("classnames/bind");

ToxicityResult.propTypes = {
	poisonSeverity: PropTypes.number,
};

function ToxicityResult({ poisonSeverity }) {
	const classStyles = {
		meterNeedle: styles.meterNeedle,
		hidden: styles.hidden,
		level1: styles.level1,
		level2: styles.level2,
		level3: styles.level3,
	};

	const cx = classNames.bind(classStyles);

	const needleClasses = cx({
		meterNeedle: true,
		hidden: poisonSeverity === 0 ? true : false,
		level1: poisonSeverity === 1 ? true : false,
		level2: poisonSeverity === 2 ? true : false,
		level3: poisonSeverity === 3 ? true : false,
	});

	const severityMsgs = {
		0: "We are having trouble scanning this! Please reach out to your vet or call Animal Poison Control for more information!",
		1: "That's probably not poisonous. However, if your dog is showing symptoms such as vomiting, diarrhea, excessive drooling, lack of coordination, weakness and/or lethargy, excessive thirst and/or urination, unusual behavior, or has collapsed, you should contact your vet or call ASPCA Animal Poison Control.",
		2: "That may or may not be poisonous. It's best to contact your vet or call ASPCA Animal Poison Control.",
		3: "That can be very poisonous. Contact your vet or call ASPCA Animal Poison Control now!",
	};

	return (
		<div className={styles.toxicityReport}>
			<div className={styles.toxicMeter}>
				<div className={needleClasses}></div>
			</div>
			<p className={styles.toxicityResult}>{severityMsgs[poisonSeverity]}</p>
			<PosionControlCTA poisonSeverity={poisonSeverity} />
		</div>
	);
}

export default ToxicityResult;
