function trackVideo(currentVideo) {
  function pushDataLayer(obj) {
    window.dataLayer.push(obj);
  }

  function setGTMLabel(label, obj) {
    obj.eventLabel = label;
  }
  const timeMap = new Map([
    ["Video 25%", true],
    ["Video 50%", true],
    ["Video 75%", true],
    ["Video 100%", true],
  ]);

  function fireGTM(label) {
    const acceptValue = timeMap.get(label);
    const gtmObj = {
      event: "gtm-video",
      eventCategory: "Video",
      eventAction: "Event",
      eventLabel: "",
    };
    if (acceptValue) {
      timeMap.set(label, false);
      setGTMLabel(label, gtmObj);
      pushDataLayer(gtmObj);
    }
    if (label === "Video Play" || label === "Video Pause") {
      setGTMLabel(label, gtmObj);
      pushDataLayer(gtmObj);
    }
  }

  currentVideo.onplay = () => {
    fireGTM("Video Play");
  };

  currentVideo.onpause = () => {
    fireGTM("Video Pause");
  };

  currentVideo.addEventListener("timeupdate", function () {
    const videoLength = Math.floor(currentVideo.duration);
    const currentPosition = Math.floor(currentVideo.currentTime);

    if (currentPosition === videoLength * 0.25) {
      fireGTM("Video 25%");
    }
    if (currentPosition === videoLength * 0.5) {
      fireGTM("Video 50%");
    }
    if (currentPosition === videoLength * 0.75) {
      fireGTM("Video 75%");
    }
    if (currentPosition === videoLength) {
      fireGTM("Video 100%");
    }
  });
}

export default trackVideo;

// ABOUT
// This function track video metrics and pushes to the datalayer for GTM analytics.

// IMPLEMENTATION
/*
import trackVideo from "../functions/handleVideoAnalytics";
Call the function: trackVideo(video);
Can also be called for multiple videos.
    videoArray.forEach(video => trackVideo(video));
*/
