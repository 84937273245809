import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "../styles/SlidingToggle.module.scss";
const classNames = require("classnames/bind");

SlidingToggle.propTypes = {
  data: PropTypes.object.isRequired,
  keyboardActive: PropTypes.bool.isRequired,
};

function SlidingToggle({ data, keyboardActive }) {
  const [isActive, setActive] = useState(false);

  const altTextStr = `Consuming ${
    data.name
  } may result in your dog having the following symptoms: ${data.description.toLowerCase()}.`;

  const handleClick = (e) => {
    const activeElement = e.target;
    setActive(!isActive);
    handleGTM(activeElement);
  };

  function handleGTM(element) {
    if (!isActive) {
      element.dataset.gtmEventLabel = data.name + " close";
      return;
    }
    element.dataset.gtmEventLabel = data.name;
  }

  const classStyles = {
    active: styles.active,
    toggle: styles.toggle,
    foods: styles.foods,
    symptoms: styles.symptoms,
    btn: styles.btn,
    keyboard: styles.keyboard,
  };

  const cx = classNames.bind(classStyles);

  const toggleClass = cx({
    toggle: true,
  });

  const foodsClass = cx({
    foods: true,
    active: isActive,
  });

  const symptomsClass = cx({
    symptoms: true,
    active: isActive,
  });

  const btnClass = cx({
    btn: true,
    active: isActive,
    keyboard: keyboardActive,
  });

  return (
    <div className={toggleClass}>
      <div className={foodsClass}>
        <p aria-hidden="true">{data.name}</p>
      </div>

      <div className={symptomsClass}>
        <p aria-hidden="true">{data.description}</p>
      </div>

      <button
        type="button"
        className={btnClass + " gtm-link-internal"}
        onClick={handleClick}
        data-gtm-event-label={data.name}
      >
        <img
          className={styles[`icon-${data.id}`]}
          src={data.img}
          alt={altTextStr}
        />
      </button>
    </div>
  );
}

export default SlidingToggle;
